import mapImages from '@/util/mapImages'
import Util from '@/util'
import BaseStore from './baseStore'

const ol = window.ol

class LprStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.hoveredId = null
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      name: 'STRING'
    }
    this.lprsCounter = {
      lprOnlineCount: 0,
      lprOfflineCount: 0
    }
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ])),
      name: item.id
    });
    item.typeOfItem = 'lprs'
    marker.setId('lprs__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)
    if (item.status === 'active' || item.status === 'partial') {
      this.lprsCounter.lprOnlineCount++
    } else {
      this.lprsCounter.lprOfflineCount++
    }
    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if ((item.status === 'active' || item.status === 'partial') && (record.status !== 'active' || record.status !== 'partial')) {
      this.lprsCounter.lprOnlineCount++
      this.lprsCounter.lprOfflineCount--
    }
    if ((item.status !== 'active' || item.status !== 'partial') && (record.status === 'active' || record.status === 'partial')) {
      this.lprsCounter.lprOnlineCount--
      this.lprsCounter.lprOfflineCount++
    }
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker.getGeometry().setCoordinates(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ]))
    }
    const visible = this._isVisible(item)

    Object.assign(record, item)
    this.notify(item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    this._updateMarker(item)
    return null
  }

  _getStyle(item) {
    let icon = mapImages.lpr[this.map.mapMode].down
    if (item.status !== 'active' || item.status !== 'partial') {
      icon = mapImages.lpr[this.map.mapMode].up
      if (item.selected) {
        icon = mapImages.lpr[this.map.mapMode].up_selected
      }
    } else if (item.selected) {
      icon = mapImages.lpr[this.map.mapMode].down_selected
    }
    return this._getIcon(icon, item.name, {
      scale: mapImages.lpr.scale,
      offsetY: mapImages.lpr.offsetY,
      offsetX: mapImages.lpr.offsetX
    })
  }

  _getStyleSelected(item) {
    let icon = mapImages.lpr[this.map.mapMode].down_selected
    if (item.status !== 'active' || item.status !== 'partial') {
      icon = mapImages.lpr[this.map.mapMode].up_selected
    }
    return this._getIcon(icon, item.name, {
      scale: mapImages.lpr.scale,
      offsetY: mapImages.lpr.offsetY,
      offsetX: mapImages.lpr.offsetX
    })
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      marker.setStyle(this._getStyle(marker.get('record')))
      this.hoveredId = null
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')))
      this.hoveredId = id
    }
  }

  async updateMarkersStyle() {
    this.markers.forEach(async (marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }
}

export default LprStore
